import React, { Component } from 'react';

export default class Contact extends Component {
    render() {
        return (
            <div>
                <h5>Contact</h5>
                <p>Email: mendozaarielr@gmail.com</p>
                <p>Github: </p>
                <p>Linkedin: </p>
            </div>
        );
    }
}